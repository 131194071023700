import React, { Component } from 'react';
import { wrapDisplayName } from 'recompose';
import Rollbar from './rollbar';

// Internal: HOC for our Next.js <App>, which fetches the featureFlag values
// from the backend and passes them down to the app.
//
export default function withFeatureFlags(App) {
  class HOCComponent extends Component {
    render() {
      return <App {...this.props} />
    }
  }

  HOCComponent.getInitialProps = async (args) => {
    let props = {};
    if (App.getInitialProps) {
      props = await App.getInitialProps(args);
    }

    // NOTE: This allows us to to stub the settings from inside a Cypress test.
    if (args.ctx.query.cypress_stub == 'true') {
      props.featureFlags = {};
      for (const [key, value] of Object.entries(args.ctx.query)) {
        if (value == 'true') {
          props.featureFlags[key] = true;
        } else {
          props.featureFlags[key] = false;
        }
      }

      return props;
    }

    // NOTE: for all the other tests we assume the feature flags are all off.
    if (process.env.CYPRESS == 'true') {
      return props;
    }

    try {
      // Fetch the feature flags from the backend using /settings.
      const featureFlags = await args.ctx.backendClient.get("/settings")
      if (featureFlags) {
        props.featureFlags = featureFlags;
      }
    } catch (error) {
      const message = "Failed to fetch feature flags"
      Rollbar.warning(message, error);
    }

    return props;
  }

  // Modify the display name so we know it's got the backend client.
  HOCComponent.displayName = wrapDisplayName(App, 'withBackendClient');

  return HOCComponent;
}
