import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import JssProvider from 'react-jss/lib/JssProvider';
import getPageContext from '../src/getPageContext';
import withBackendClient from '../src/withBackendClient';
import withCurrentCustomer from '../src/withCurrentCustomer';
import withFeatureFlags from '../src/withFeatureFlags';
import CurrentCustomerContext from '../src/currentCustomerContext';
import FeatureFlagsContext from '../src/featureFlagsContext';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import TagManager from 'react-gtm-module';
import Zendesk from "../components/zendesk";
import CookieConsentBanner from '../components/cookieConsentBanner';
class MyApp extends App {
  constructor(props) {
    super(props);
    this.pageContext = getPageContext();
  }

  pageContext = null;

  async componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    // If we got given the httpReferer (see definition of getInitialProps
    // below), store it in localStorage.
    if (this.props.httpReferer) {
      try {
        localStorage.setItem('httpReferer', this.props.httpReferer);
      } catch(e) {
        // Access denied, do nothing
      }
    }

    // <https://github.com/stripe/stripe-js#import-as-a-side-effect>
    await import('@stripe/stripe-js');
    this.initialiseTagManager()
  }

  initialiseTagManager() {
    if (getCookieConsentValue() && GTM_TAG_ID) {
      TagManager.initialize({
        gtmId: GTM_TAG_ID
      })
    };
  };

  handleAccept() {
    this.initialiseTagManager();
    this.props.backendClient.post('/cookie_events', {accepted: true});
  };

  handleDecline() {
    this.props.backendClient.post('/cookie_events', {accepted: false})
  };

  render() {
    const { Component, pageProps, backendClient, currentCustomer, featureFlags } = this.props;

    return (
      <JssProvider
        registry={this.pageContext.sheetsRegistry}
        generateClassName={this.pageContext.generateClassName}
      >
        <FeatureFlagsContext.Provider value={featureFlags}>
          {/* CurrentCustomerProvider provides the currentCustomer as context
              to components that want it */}
          <CurrentCustomerContext.Provider value={currentCustomer}>

            <Head>
              <title>Blink!</title>
            </Head>

            {/* Pass pageContext to the _document though the renderPage enhancer
                to render collected styles on server side. */}
            <Component
              pageContext={this.pageContext}
              backendClient={backendClient}
              {...pageProps}
            />

            <Zendesk />

            <CookieConsentBanner
              handleAccept={() => this.handleAccept()}
              handleDecline={() => this.handleDecline()}
            />
          </CurrentCustomerContext.Provider>
        </FeatureFlagsContext.Provider>
      </JssProvider>
    );
  }
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  const req = appContext.ctx.req;
  if (!req) {
    // Client side, we're done.
    return { ...appProps };
  }

  // Get the referer
  const referer = req.headers.referer;
  // Ignore ones that look like they're internal. This is not a perfect
  // regex but I think it'll do.
  if (/^https:\/\/[^\/]*\.?blinkcats\.co\.uk\//.test(referer)) {
    return { ...appProps };
  }
  // Pass it up
  return { ...appProps, httpReferer: referer };
}

export default withCurrentCustomer(withBackendClient(withFeatureFlags(MyApp)));
