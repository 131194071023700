import React from "react";

// Borrowed from <https://github.com/zeit/customer-support-examples/blob/master/lib/zendesk.js>.
// Render this component within `_app` to have the chat persist between page
// loads.
class Zendesk extends React.Component {
 
  // Insert the Zendesk script in the page
  insertScript() {
    const script = document.createElement("script");
    script.defer = true;
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    document.body.appendChild(script);
  }


  // On mount (which means we are client side), do some stuff.
  componentDidMount() {
    // Double check we definitely have a window!
    if (ZENDESK_KEY && typeof window !== "undefined") {
      this.insertScript();
    }
  }

  // This doesn't actually render anything 🙃.
  render() {
    return <React.Fragment />;
  }
}

export default Zendesk;
