let Rollbar;

if (process.browser) {
  // Use Rollbar set up in snippet (see _document.js)
  Rollbar = window.Rollbar;
} else {
  // Require Rollbar instead
  const rollbar = require('rollbar');
  // Set it up
  Rollbar = new rollbar({
    // Use server access token
    accessToken: process.env.ROLLBAR_SERVER_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    autoInstrument: false,
    payload: {
      environment: ROLLBAR_ENV
    }
  });
}

// Disable Rollbar in non-production environments
if (process.env.NODE_ENV !== 'production') {
  Rollbar.configure({enabled: false});
}

export default Rollbar;
