import React, { Component } from 'react';
import BackendClient from './backendClient';
import { wrapDisplayName } from 'recompose';

// Internal: HOC for our Next.js <App>, which exposes a BackendClient to both
// our page's getInitialProps and the page props themselves.
//
// We may want it to go into the entire app context instead of props, if we
// ever want to use it on inner components.
export default function withBackendClient(App) {
  class HOCComponent extends Component {
    render() {
      const backendClient = new BackendClient({});
      return <App backendClient={backendClient} {...this.props} />
    }
  }

  HOCComponent.getInitialProps = async (args) => {
    let props = {};
    if (App.getInitialProps) {
      args.ctx.backendClient = new BackendClient(args.ctx);
      props = await App.getInitialProps(args);
    }

    return props;
  }

  // Modify the display name so we know it's got the backend client.
  HOCComponent.displayName = wrapDisplayName(App, 'withBackendClient');

  return HOCComponent;
}
