import React, { Component } from 'react';
import { wrapDisplayName } from 'recompose';
import Rollbar from './rollbar';

// Use this context to get information about the current customer, which is
// embedded within a JWT.
//
// You can check what's available in the JWT in app/models/json_web_token.rb
// in the backend codebase.
export default function withCurrentCustomer(App) {
  class HOCComponent extends Component {
    render() {
      return <App {...this.props} />
    }
  }

  HOCComponent.getInitialProps = async (args) => {
    let props = {};
    if (App.getInitialProps) {
      props = await App.getInitialProps(args);
    }

    // Get the current customer
    const req = args.ctx.req;

    if (req) {
      if (req.cookies.token) {
        // Import JWT dynamically so we don't bundle browserify in our client
        // bundle.
        const jwt = (await import('jsonwebtoken'));
        try {
          const token = jwt.verify(req.cookies.token, process.env.JWT_SECRET_KEY);
          // Invalidate tokens created before we started to add the `bento` flag
          if (token.iat > 1710932031) { // ~20th March 11am
            props.currentCustomer = token;
          }
        } catch(err) {
          const message = '[JWT] decode fail'
          Rollbar.warning(message, err);
        }
      }
    } else {
      try {
        const verifyResponse = await fetch('/verifyToken', {credentials: 'same-origin'})
        if (verifyResponse.ok) {
          const token = await verifyResponse.json();
          props.currentCustomer = token;
        }
      } catch(err) {
        const message = '[JWT] decode fail'
        Rollbar.warning(message, err);
      }
    }

    return props;
  }

  HOCComponent.displayName = wrapDisplayName(App, 'withCurrentCustomer');

  return HOCComponent;
}
