/* eslint-disable no-underscore-dangle */

import { SheetsRegistry } from 'jss';
import { createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';

// Blink brand colours.
const brandGreen = '#98bea8';
const brandGrey = '#4d4d4d';
const brandRed = '#da9189';

// Theme colours.
const primaryColor = brandGreen;
const secondaryColor = brandRed;
const textGrey  = brandGrey;

const primaryLightColor = '#c1d8ca';
const secondaryLightColor = '#e8bdb8';

const disabledButtonColor = '#e0e0e0'


// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    color: textGrey,
    fontFamily: 'Montserrat, Comfortaa, Helvetica, Arial, sans-serif',
    lineHeight: 1.5
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '1.75rem',
        lineHeight: 1.5,
        marginBottom: '1.35rem',
      },
      h2: {
        fontSize: '1.5rem',
        lineHeight: 1.5
      },
      h3: {
        fontSize: '1.25rem',
        lineHeight: 1.5
      },
      body1: {
        fontSize: 16
      },
      subtitle1: {
        lineHeight: 1.5
      },
      subtitle2: {
        fontSize: '1rem',
        lineHeight: 1.5
      },
      overline: {
        lineHeight: 1.5
      }
    },
    MuiAppBar: {
      root: {
        paddingTop: 16,
        paddingBottom: 16,
      },
      colorPrimary: {
        color: 'white'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: primaryColor,
        color: 'white',
        width: 250
      }
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 10
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 12,
        textAlign: 'left'
      }
    },
    MuiInput: {
      root: {
        color: textGrey
      }
    },
    MuiButton: {
      root: {
        marginTop: '1rem',
        marginBottom: '1rem'
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        color: 'white'
      },
      "&$disabled": {
        backgroundColor: disabledButtonColor
      }
    },
    MuiTabs: {
      indicator: {
        display: 'none'
      }
    },
    MuiTab: {
      root: {
        borderStyle: 'solid',
        borderColor: primaryColor,
        color: textGrey,
        paddingTop: 10,
        paddingBottom: 10,

        "&$selected": {
          backgroundColor: primaryColor,
          color: 'white'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        color: 'white',
        textTransform: 'uppercase',
        fontWeight: 500,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 18,
        paddingBottom: 18
      }
    }
  },
  palette: {
    primary: {
      main: primaryColor,
      light: primaryLightColor
    },
    secondary: {
      light: secondaryLightColor,
      main: secondaryColor
    },
    accent: {
      default: secondaryLightColor
    },
    background: {
      default: 'white'
    },
    dark: {
      default: textGrey
    }
  }
});

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  };
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext();
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
