import fetch from 'isomorphic-fetch';
import Router from 'next/router';
import Rollbar from './rollbar';

// Internal: A client for our backend API. You shouldn't initialize one of
// these directly, it is made available thanks to `withBackendClient`, both
// as a property of the argument passed to `Page#getInitialProps`, and
// as a property on every page.
export default class BackendClient {

  headers = {}
  res = null

  constructor({req, res}) {
    this.res = res;
    if (req) {
      this.headers['Cookie'] = req.headers.cookie;
    }
  }
  
  // Handle an API GET request. Returns a promise.
  async get(path) {
    const options = {
      method: 'get'
    };
    return this.request(path, options)
  }

  // Handle a API PUT request. Returns a promise.
  async put(path, payload) {
    const options = {
      method: 'put',
      body: JSON.stringify(payload),
    };
    return this.request(path, options);
  }

  // Handle a API POST request. Returns a promise.
  async post(path, payload) {
    const options = {
      method: 'post',
      body: JSON.stringify(payload)
    };
    return this.request(path, options);
  }

  async delete(path, payload) {
    const options = {
      method: 'delete',
      body: JSON.stringify(payload)
    };
    return this.request(path, options);
  }

  // Handle an API request. Returns a promise.
  async request(path, options) {
    let url = API_BASE_URL + path;

    // Set our request options.
    options = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...this.headers
      },
      credentials: 'include',
      ...options
    };

    // Make API call.
    let response;
    try {
      response = await fetch(url, options);
    } catch(e) {
      Rollbar.warning(e, { path });
      throw({ errors: [{title: 'Request failed', detail: 'Network error'}] });
    }

    let json;
    try {
      json = await response.json();
    } catch(e) {
      // Response wasn't JSON.
      Rollbar.error(e, { path });
      throw({ errors: [{title: 'Invalid response', detail: 'Invalid response', statusCode: response.status}] });
    }

    if (response.ok) {
      console.log(path, 'OK', `Response length: ${json.length}`);
      return json;
    } else if (response.status == 401) {
      if (this.res) {
        this.res.redirect(302, '/login?required=true');
      } else {
        Router.push('/login?required=true');
      }
    } else {
      console.log(path, response.status, json);
      throw {
        ...json,
        status: response.status 
      };
    }
  }
}
